
import { config } from 'process';
import FormData from 'form-data';
import axios, { AxiosResponse } from 'axios';

 const trackvitals=(vitals)=>{

    console.log("uuid::",vitals.get('uuid'));
    console.log("logs::",vitals.get('log'));
    console.log("logtype::",vitals.get('log_type'));
  //  console.log("vitalsing2::",vitals.get("log"));
//     axios.post('https://3g2fy811x9.execute-api.us-east-1.amazonaws.com/dev/track',
//     {"ses_token":"0c518626-4adb-47cb-89f7-f19a32912675",
//    "uuid":"6de31b9b-f9cd-4332-88c0-ec68a37538a6",
//     "log":{"HeartRate":"45",
//     "OxygenSaturation":"99",
//     "BreathingRate":"46",
//     "Sdnn":"49",
//     "StressLevel":"60",
//     "BloodPressure":"88",
//     "Hemoglobin":"78",
//     "HemoglobinA1C":"18",
//     "PRQ":"8",},
//     "platform":"ios",
//     "scan_id":vitals,
//     "log_type":1,

//   },{headers:{"Content-Type":"multipart/form-data"}})
//     .then(function (response) {
//       console.log(response);
//       return response.data;
//     })
//     .catch(function (error) {
//       console.log(error);
//     });

//     const formData = new FormData();
//     const dateTime = new Date();
//     formData.append("ses_token","3b88168a-9005-46b9-91a1-48ba632f335c");
//     formData.append("uuid","6de31b9b-f9cd-4332-88c0-ec68a37538a6"),
//     formData.append("log_typ",`${1}`);
//     formData.append("platform","ios");
//     formData.append("scan_id",`${dateTime.getDay().toString()+dateTime.getMonth().toString()+dateTime.getFullYear().toString()
//       +dateTime.getHours().toString()+dateTime.getMinutes().toString()+dateTime.getSeconds().toString()+
//       dateTime.getMilliseconds().toString()}`);
//       formData.append("log",`${{"HeartRate":"59",
//       "OxygenSaturation":"100",
//       "BreathingRate":"59",
//       "Sdnn":"34",
//       "StressLevel":"45",
//       "BloodPressure":"58",
//       "Hemoglobin":"22",
//       "HemoglobinA1C":"39",
//       "PRQ":"7.5"}}`)
//     console.log("vtlogs::",formData);

//   try {
//     const response: AxiosResponse = await axios.post('https://3g2fy811x9.execute-api.us-east-1.amazonaws.com/dev/track', formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
   
//     // Handle the response
//     console.log(response.data);
//     return response.data;
//   } catch (error) {
//     // Handle errors
//     console.error(error);
//   }
// let map = new Map();
//   var data={"log":{"HeartRate":"59",
//   "OxygenSaturation":"100",
//   "BreathingRate":"59",
//   "Sdnn":"34",
//   "StressLevel":"45",
//   "BloodPressure":"58",
//   "Hemoglobin":"22",
//   "HemoglobinA1C":"39",
//   "PRQ":"7.5"},"ses_token":"3b88168a-9005-46b9-91a1-48ba632f335c","uuid":"6de31b9b-f9cd-4332-88c0-ec68a37538a6",
//  "scan_id":vitals,"log_typ":1,"platform":"ios"}
    
 return fetch(`https://3g2fy811x9.execute-api.us-east-1.amazonaws.com/dev/track`,{
    method:"POST",
    headers:{
    Accept:"application/json"
    },
    body:vitals,
 }).then(response=>{
    console.log("vitals response:",response);
    return response.json();
 }).catch(error=>{
  console.log("failed to post track vitals");
 });
}
//}
export default trackvitals;
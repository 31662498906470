import React from 'react';
import { Flex } from './shared/Flex';
import styled from 'styled-components';

import Lottie from "lottie-react";
import groovyWalkAnimation from "assets/animation_lbpzye9x.json";

const LoadingWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const Message = styled.h2`
  font-size: 18px;
  color: #01061b;
  font-weight: normal;
`;

const Loader = () => (
  <LoadingWrapper>
<Lottie animationData={groovyWalkAnimation} />
  </LoadingWrapper>
);
export default Loader;

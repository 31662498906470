import { useEffect, useState } from 'react';
const useCameras = (): MediaDeviceInfo[] => {
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  //const [videoDevices,setvideoDevices] =useState<MediaDeviceInfo>();
  function showAlert() {
    alert("hellow");
  };
  useEffect(() => {
  
    (async () => {
     // let videoDevices=null;
        // if(isMobileDevice){
        //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        //     navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: 'environment' } } })
        //       .then((stream) => {
        //         if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        //           navigator.mediaDevices.enumerateDevices()
        //             .then((devices) => {
        //               const backCamera = devices.find((device) => device.label.toLowerCase().includes('default'));
        //               if (backCamera) {
        //                 devices&&  setCameras(devices)
        //                 //binah.init({ videoDeviceId: backCamera.deviceId });
        //               } else {
        //                 console.error('Back camera not found.');
        //               }
        //             })
        //             .catch((error) => {
        //               console.error('Error enumerating devices:', error);
        //             });
        //         } else {
        //           console.error('Device enumeration not supported.');
        //         }
        //         //binah.init({ videoElement: document.getElementById('videoElement'), mediaStream: stream });
        //       })
        //       .catch((error) => {
        //         console.error('Error accessing camera:', error);
        //       });
        //   } else {
        //     console.error('getUserMedia not supported.');
        //   }
        // }
       //else{
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices =  devices.filter((device) => 
            (device.kind === 'videoinput') 
             
           );
           videoDevices && setCameras(videoDevices);
      // }
    
    })();
  }, []);
  return cameras;
};

export default useCameras;

import React from 'react';
import styled from 'styled-components';
import logo2 from '../assets/logo2.svg';

const Img = styled.img`
  height: 45px;
  padding-top: 0px;
  pointer-events: none;
`;

const Logo = () => {
   return <div style={{height:"45px",width:"45px",borderRadius:"30px",background:"white"
   ,justifyContent:"center",display:"flex"}}>
    <Img src={logo2} />
   </div>;
};

export default Logo;



function calculateScoreFromLog(log):number{
    console.log('calculating vitals signs::',log.oxygenSaturation,log.heartRate.value)
   let redvitals:number[]=[];
 let findredvitals:number[]=[];
    // color integers 0 -> grey, 1-> green , 2 -> orange , 3 -> red
   // const logMap = JSON.parse();
    const logMap=log;
   // console.log("calculate log:",logMap.bloodPressure.value['diastolic']);

    let isHeartRate :number= logMap.heartRate?.value ==undefined||
        logMap.heartRate.value.toString() == '' || logMap.heartRate.value == null
            ? 0
            : parseInt(logMap.heartRate.value) > 150
                ? 3
                : parseInt(logMap.heartRate.value) > 100
                    ? 2
                    : parseInt(logMap.heartRate.value) >= 60 &&
                    parseInt(logMap.heartRate.value) <= 100
                        ? 1
                        : parseInt(logMap.heartRate.value) < 60
                            ? 2
                            : parseInt(logMap.heartRate.value) < 50
                                ? 3
                                : 0;

    let isOxygenSaturation :number=logMap.oxygenSaturation?.value==undefined|| logMap.oxygenSaturation.value == '' ||
    logMap.oxygenSaturation.value == null
        ? 0
        : parseInt(logMap.oxygenSaturation.value) < 90
            ? 3
            : (parseInt(logMap.oxygenSaturation.value) >= 90 &&
            parseInt(logMap.oxygenSaturation.value) <= 95)
                ? 2
                : 1;
    let isHemoglobin:number =logMap.hemoglobin?.value==undefined||
        logMap.hemoglobin.value== '' || logMap.hemoglobin.value.toString() == null
         
            ? 0
            : parseFloat(logMap.hemoglobin.value) > 16.6
                ? 2
                : parseFloat(logMap.hemoglobin.value) < 12
                    ? 2
                    : (parseFloat(logMap.hemoglobin.value) >= 11 &&
                    parseFloat(logMap.hemoglobin.value)<= 16)
                        ? 1
                        : 0;

    let isBreathingRate :number=logMap.breathingRate?.value==undefined|| logMap.breathingRate.value == '' ||
            logMap.breathingRate.value == null 
        ? 0
        : parseInt(logMap.breathingRate.value) > 25
            ? 3
            : (parseInt(logMap.breathingRate.value) >= 20 &&
            parseInt(logMap.breathingRate.value) <= 25)
                ? 2
                : (parseInt(logMap.breathingRate.value) >= 12 &&
                parseInt(logMap.breathingRate.value) <= 20)
                    ? 1
                    : parseInt(logMap.breathingRate.value) < 12
                        ? 3
                        : 0;

    let isHrv =logMap.hrvSdnn?.value==undefined|| logMap.hrvSdnn?.value == '' || logMap.hrvSdnn?.value == null
        ? 0
        : parseFloat(logMap.hrvSdnn.value) > 50
            ? 1
            : parseFloat(logMap.hrvSdnn.value) >= 25 &&
            parseFloat(logMap.hrvSdnn.value) <= 50
                ? 2
                : parseFloat(logMap.hrvSdnn.value) < 25
                    ? 3
                    : 0;

    let isHemoglobinA1C:number = logMap.hemoglobinA1c?.value==undefined||logMap.hemoglobinA1c?.value== '' ||
    logMap.hemoglobinA1c?.value == null
        ? 0
        : parseFloat(logMap.hemoglobinA1c?.value)> 6.5
            ? 3
            : (parseFloat(logMap.hemoglobinA1c?.value) >= 5.6 &&
                    parseFloat(logMap.hemoglobinA1c?.value) <= 6.4)
                ? 2
                : logMap.hemoglobinA1c?.value < 5.6
                    ? 1
                    : 0;

    let isStressLevel:number =
        logMap.stress.value == logMap.stress?.value==undefined || logMap.stress?.value == null
            ? 0
            : (parseInt(logMap.stress.value) == 1 ||
            parseInt(logMap.stress.value) == 2)
                ? 1
                : parseInt(logMap.stress.value) == 3
                    ? 2
                    : (parseInt(logMap.stress.value)== 4 ||
                    parseInt(logMap.stress.value) == 5)
                        ? 3
                        : 0;

    let isBloodPressure:number = (logMap.bloodPressure?.value==undefined||logMap.bloodPressure?.value == '' ||
            logMap.bloodPressure?.value == null)
        ? 0
        : parseInt(logMap.bloodPressure?.value['systolic'].toString()) > 120 &&
        parseInt(logMap.bloodPressure.value['diastolic'].toString()) >=
                    70 && logMap.bloodPressure?.value['systolic'].toString() <=160 &&
                    parseInt(logMap.bloodPressure?.value.toString().toString().split('/')[1]) <=
                    99
            ? 2
            : parseInt(logMap.bloodPressure?.value['systolic'].toString()) <=
                        120 &&
                        parseInt(
                            logMap.bloodPressure.value['diastolic'].toString()) <=
                        80
                ? 1
                : parseInt(logMap.bloodPressure?.value['systolic'].toString()) >
                            170 &&
                            parseInt(logMap.bloodPressure?.value.toString()
                                .toString()
                                .split('/')[1]) >
                            100
                    ? 3
                    : 0;

    /*int isCholesterol =
        (logMap['Hrv'].toString() == '' || logMap['Hrv'] == null)
            ? 0
            : double.parse(logMap['Hrv'].toString()) > 50
                ? 1
                : double.parse(logMap['Hrv'].toString()) >= 25 &&
                        double.parse(logMap['Hrv'].toString()) <= 50
                    ? 2
                    : double.parse(logMap['Hrv'].toString()) < 25
                        ? 3
                        : 0; */
    let isPrq:number =
        (logMap.prq?.value == '' || logMap.prq?.value == null||logMap.prq?.value==undefined)
            ? 0
            : parseFloat(logMap.prq?.value) >= 4 && parseFloat(logMap.prq?.value)<=5
                ? 1
                : /*double.parse(logMap['PRQ'].toString()) >5.5 ||
                        double.parse(logMap['PRQ'].toString()) <4*/
                        parseFloat(logMap.prq?.value) <3.5||parseFloat(logMap.prq?.value) >5.5

                    ? 3
                    : parseFloat(logMap.prq?.value) > 5 && parseFloat(logMap.prq?.value)<=5.5||
                    parseFloat(logMap.prq?.value) >3.5 &&
                    parseFloat(logMap.prq?.value) <3.9
                        ? 2
                        : 0;
   redvitals.push(isPrq);
   redvitals.push(isHeartRate);
   redvitals.push(isOxygenSaturation);
   redvitals.push(isHemoglobin);
   redvitals.push(isBreathingRate);
   redvitals.push(isHrv);
   redvitals.push(isHemoglobinA1C);
   redvitals.push(isStressLevel);
   redvitals.push(isBloodPressure);
    console.log(
        'this is inside calculate : $isHeartRate , $isOxygenSaturation ,$isHemoglobin ,$isBreathingRate ,$isHrv ,$isHemoglobinA1C ,$isStressLevel ,$isBloodPressure ,$isPrq');
  for( let i:number=0;i<redvitals.length;i++){
    if(redvitals[i]==3){
      findredvitals.push(i);
      console.log("redvitalenght:${findredvitals.length}");
    }
  }
    if(findredvitals.length>=3) {
      console.log('more than two vitals are red : ::: 1');
      return 1;
    }else if (isHeartRate == 3 ||
        isOxygenSaturation == 3 ||
        isHemoglobin == 3 ||
        isBreathingRate == 3 ||
        isHrv == 3 ||
        isHemoglobinA1C == 3 ||
        isStressLevel == 3 ||
        isBloodPressure == 3 ||
        isPrq == 3) {
            console.log('this is inside calculate : ::: 1');
      return 2;
    }
    else if (isHeartRate == 1 &&
        isBreathingRate == 1 &&
        isStressLevel == 1 &&
        isHrv == 1 &&
        isOxygenSaturation == 1 &&
        isBloodPressure == 1 && isPrq==1) {
            console.log('this is inside calculate : ::: 2');
      return 5;
    } else if (isHeartRate == 1 &&
        isBreathingRate == 1 &&
        isOxygenSaturation == 1 &&
        isBloodPressure == 1&& isPrq==1) {
            console.log('this is inside calculate : ::: 3');
      return 4;
    } else if (isHeartRate == 2 &&
        isBreathingRate == 2 &&
        isOxygenSaturation == 2 &&
        isBloodPressure == 2) {
            console.log('this is inside calculate : ::: 4');
      return 3;
    } else if (isHeartRate == 2 &&
        isBreathingRate == 2 &&
        isOxygenSaturation == 2 &&
        isPrq==2) {
            console.log('this is inside calculate : ::: 3');
      return 3;
    } else if(isHeartRate == 0 &&
        isOxygenSaturation == 0 &&
        isHemoglobin == 0 &&
        isBreathingRate == 0 &&
        isHrv == 0 &&
        isHemoglobinA1C == 0 &&
        isStressLevel == 0 &&
        isBloodPressure == 0 &&
        isPrq == 0) {
            console.log('this is inside calculate : ::: 0');
      return 0;
    }
    else {
        console.log('this is inside calculate : ::: 5');
      return 3;
    }

   
  }

  export default calculateScoreFromLog;
import React from 'react';
import styled from 'styled-components';
import StatsBox from './StatsBox';
import { Flex } from './shared/Flex';
import { isMobile } from '@binah/web-sdk';
import ProgressBar from './Progressbar'
import calculateScoreFromLog from '../utils/Utils';
import trackvitals from "../network/coreApi";

const Wrapper = styled(Flex)`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 45px;
  // background-color: rgba(255, 255, 255, 0.8);
  background-color: #4285F4;
  border-radius: 25px;
  padding: 13px 50px;
  bottom: 40px;
  box-sizing: border-box;
`;

const BoxesWrapper = styled(Flex)`
  gap: 30px;
`;

interface VitalSign<T> {
  value: T;
  isEnabled: boolean;
}

interface IStats {
  isloading:boolean,onClick:()=>void,
  isMeasuring:boolean
  /**
   *  Object - contains health stats info
   */
  vitalSigns: {
    heartRate: VitalSign<number>;
    breathingRate: VitalSign<number>;
    stress: VitalSign<number>;
    hrvSdnn: VitalSign<number>;
    spo2: VitalSign<number>;
    bloodPressure: VitalSign<BloodPressureValue>;
  };
  toggle:boolean,
  isScanReadyToSend:boolean,
  ses_token:string,
  uuid:string,
  handleSetScanReadyToSend:Function,
  setTheMsg:Function,
  setIsSendingMessage:Function,
}

export type BloodPressureValue = {
  systolic: number;
  diastolic: number;
};


const sentToApi=(vitalSigns:any,ses_token:string,uuid:string,setTheMsg:Function)=>{
    
  // console.log("scanning;:::",`${new Date().toLocaleString()}`);
  const dateTime = new Date();

  const formdata = new FormData();
  
  const scanid=dateTime.getDay().toString()+dateTime.getMonth().toString()+dateTime.getFullYear().toString()
  +dateTime.getHours().toString()+dateTime.getMinutes().toString()+dateTime.getSeconds().toString()+
  dateTime.getMilliseconds().toString();
  const log_type=1;

  const urlParams = new URLSearchParams(window.location.search);
const share_link_id = urlParams.get('id');

  const score=calculateScoreFromLog(vitalSigns);
  //setscore(calculateScoreFromLog(vitalSigns));
  console.log("wellnescore::",score,ses_token,uuid);
    console.log("vitalsign;:::",vitalSigns);
    //formdata.append("ses_token",ses_token==""?"3b88168a-9005-46b9-91a1-48ba632f335c":ses_token);
   // formdata.append("uuid",uuid==""?"6de31b9b-f9cd-4332-88c0-ec68a37538a6":uuid),
   formdata.append("share_link_id", share_link_id),
    formdata.append("log_type",log_type.toString());
    formdata.append("platform","android");
    formdata.append("scan_id",scanid);
   // formdata.append("Score",`${score}`);
    formdata.append("log",JSON.stringify({"HeartRate":vitalSigns.heartRate.value==undefined?"":vitalSigns.heartRate.value,
"OxygenSaturation":vitalSigns.oxyzenssturation.value==undefined?"":vitalSigns.oxyzenssturation.value,
"BreathingRate":vitalSigns.breathingRate.value==undefined?"":vitalSigns.breathingRate.value,
"Sdnn":vitalSigns.hrvSdnn.value==undefined?"":vitalSigns.hrvSdnn.value,
"StressLevel":vitalSigns.stress.value==undefined?"":vitalSigns.stress.value,
"BloodPressure":vitalSigns.bloodPressure.value==undefined?"":vitalSigns.bloodPressure.value,
"Hemoglobin":vitalSigns.hemoglobin.value==undefined?"":vitalSigns.hemoglobin.value,
"HemoglobinA1C":vitalSigns.hemoglobinA1c.value==undefined?"":vitalSigns.hemoglobinA1c.value,
"PRQ":vitalSigns.prq.value==undefined?"":vitalSigns.prq.value,"Score":score??""}))
  trackvitals(formdata)
.then(data=>{

if(typeof(data['status'])==="number" && data['status']==1){
  window.location.assign("https://app.geras.ai/vitalsinfo?vitals="+JSON.stringify(vitalSigns)+"&score="+score);
}else{
    setTheMsg(data["message"])

}
})
}

const Stats = ({ vitalSigns ,onClick,isMeasuring,isloading,toggle,isScanReadyToSend,ses_token,uuid,handleSetScanReadyToSend,setTheMsg,setIsSendingMessage}: IStats) => {
  const bloodPressureToDisplay =
    vitalSigns.bloodPressure.value?.systolic &&
    vitalSigns.bloodPressure.value?.diastolic
      ? vitalSigns.bloodPressure.value.systolic +
        '/' +
        vitalSigns.bloodPressure.value.diastolic
      : '--';
      
      
      if(isScanReadyToSend==true){
        setIsSendingMessage(true);
          sentToApi(vitalSigns,ses_token,uuid,setTheMsg);
      }
    

  return (
  
    <Wrapper style={{color:"white",fontWeight:"500",cursor:"pointer"}} onClick={onClick}>
      {/* <BoxesWrapper> */}
       
      {toggle?"Stop Scan":"Scan now"}
       
        {/* <StatsBox
          title={'HR'}
          value={
            vitalSigns.heartRate.isEnabled
              ? vitalSigns.heartRate.value || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'BR'}
          value={
            vitalSigns.breathingRate.isEnabled
              ? vitalSigns.breathingRate.value || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'SL'}
          value={
            vitalSigns.stress.isEnabled
              ? vitalSigns.stress.value || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'SDNN'}
          value={
            vitalSigns.hrvSdnn.isEnabled
              ? vitalSigns.hrvSdnn.value || '--'
              : 'N/A'
          }
        />
        {/*<StatsBox*/}
        {/*  title={'SpO2'}*/}
        {/*  value={*/}
        {/*    vitalSigns.spo2.isEnabled ? vitalSigns.spo2.value || '--' : 'N/A'*/}
        {/*  }*/}
        {/*/>*/}
        {/* {isMobile() && (
          <StatsBox
            title={'BP'}
            value={
              vitalSigns.bloodPressure.isEnabled
                ? bloodPressureToDisplay
                : 'N/A'
            }
          />
        )} */ }
      {/* </BoxesWrapper> */}
    </Wrapper>
  );
};

export default Stats;

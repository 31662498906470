import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  isMobile,
  isTablet,
  isIos,
  HealthMonitorCodes,
  SessionState,
} from '@binah/web-sdk';
import { useMediaPredicate } from 'react-media-hook';
import {
  useError,
  useLicenseKey,
  useMeasurementDuration,
  useMonitor,
  usePageVisibility,
  usePrevious,
  useTimer,
  useWarning,
} from '../hooks';
import Stats from './Stats';
import StartButton from './StartButton';
import { mirror } from '../style/mirror';
import { Flex } from './shared/Flex';
import Timer from './Timer';
import media from '../style/media';
import InfoBar from './InfoBar';
import { ErrorAlert, InfoAlert, WarningAlert } from './alert';
import Loader from './Loader';
import { VideoReadyState } from '../types';
import TopBar from './TopBar';
import Mask from '../assets/mask.svg';
import trackvitals from "../network/coreApi";
import ProgressBar from './Progressbar'
import { time } from 'console';
import calculateScoreFromLog from '../utils/Utils';
 


const MonitorWrapper = styled(Flex)<{ isSettingsOpen: boolean }>`
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  z-index: ${({ isSettingsOpen }) => isSettingsOpen && '-1'};
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)<{ isMobile: boolean }>`
  width: auto;
  height: ${({ isMobile }) => isMobile && '100%'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${media.mobile`
    margin: 20px 0 30px 0;
  `}
`;

const VideoAndStatsWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: relative;
  justify-content: center;
  width: 100%;
  height: ${({ isMobile }) => isMobile && '100%'};
  ${media.tablet`
    width: 812px;
    height: 609px;
  `} ${media.wide`
    width: 1016px;
    height: 762px;
  `};
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;

  z-index: -1;
`;

const Img = styled.img<{ isDesktop: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: ${({ isDesktop }) => (isDesktop ? 'contain' : 'cover')};
`;

const Video = styled.video<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${() => (isIos() ? 'unset' : isMobile() ? 'cover' : 'contain')};
  ${mirror}
`;

const ButtonWrapper = styled(Flex)`
  flex: 2;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: -30px;
  ${media.mobile`
    margin-top: 50px;
  `}
  ${media.tablet`
  padding: 0;
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 42%;
  margin-right: 60px;
`}
`;

const ButtomTimerWrapper = styled(Flex)`
  display: none;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

const InfoBarWrapper = styled.div`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${media.mobile`
    flex: 0.45;
  `}
`;
const Progresswraper=styled(Flex)`
position: absolute;
flex-direction: column;
align-items: center;
justify-content: center;
height: 5px;
width:50%;
// background-color: rgba(255, 255, 255, 0.8);
background-color: #4285F4;
border-radius: 25px;
padding: 5px 5px;
bottom: 90px;
box-sizing: border-box;
`

const BinahMonitor = ({
  showMonitor,
  cameraId,
  onLicenseStatus,
  onSettingsClick,
  isSettingsOpen,
  validateUrl,
}) => {
  if (!showMonitor) {
    return null;
  }

  let video = useRef<HTMLVideoElement>(null);
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState<boolean>(
    false,
  );
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
  const isPageVisible = usePageVisibility();
  const isMediaTablet = useMediaPredicate('(min-width: 1000px)');
  const [processingTime] = useMeasurementDuration();
  const [licenseKey] = useLicenseKey();
  const [scanres,setScanres]=useState<string>('');
  const [progress, setProgress] = useState(0);
  const[track,settrack]=useState(false)
  const[ses_token,setToken]=useState('');
  const[uuid,setuuid]=useState('');
  const[toggleFlag,setToogle]=useState(false);
  const[flag,setFlag]=useState(false);
  const[barflag,setbarflag]=useState(false)
  const[showmsg,setmsg]=useState('');
  const[is_sending_message,set_is_sending_message]=useState(false);
  const[isScanReadyToSend,setScanReadyToSend]=useState(false)
  //const[score,setscore]=useState(0);
  
  const {
    sessionState,
    vitalSigns,
    offlineMeasurements,
    error,
    warning,
    info,
  } = useMonitor(
    video,
    cameraId,
    processingTime,
    licenseKey,
    null,
    startMeasuring,
  );
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);
  //const notify = (message) => toast(message);

  

  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState],
  );

  const isVideoReady = useCallback(
    () => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA,
    [],
  );

 
  const handleSetScanReadyToSend = (newState) => {
    setScanReadyToSend(newState);
  };


  const setTheMsg = (msg:string) => {
    
        setmsg(msg);
  };
  const setIsSendingMessage = (msg:boolean) => {
    
         set_is_sending_message(msg);
  };




  const handleButtonClick = useCallback(() => {

    validateUrl(true,()=>{



    
    // start the scan
    setStartMeasuring(true);

    setToogle(!toggleFlag);
    setbarflag(!barflag)


    if(barflag){
      window.location.href = window.location.href;
      return false;
    }

    setTimeout(() => {


      // stop the scanning
      setStartMeasuring(false);

    setToogle(!toggleFlag);
    setbarflag(!barflag)

   

      // add a buffer time for processing the results
        setTimeout(() => {

         

          setScanReadyToSend(true);
          setScanReadyToSend(false);
          // sentToApi();
        }, 500);


    }, processingTime * 1000);
    // countTimer(60);
    // setToogle(!toggleFlag);
    // setbarflag(!barflag)
    // if (sessionState === SessionState.ACTIVE) {

    //   console.log('scanningaaa started');
    //   setStartMeasuring(true);
    //   console.log("asdaaaa active sessionstate::",sessionState,isMeasuring);
    
    //   setLoadingTimeoutPromise(
    //     window.setTimeout(() => {

    //       console.log('scanningaaa finished');
    //       setIsLoading(true);

    //     }, processingTime * 1000),
    //   );
    // } else if (isMeasuring()) {
    //   console.log('scanningaaa in progress');
    //   console.log("ismeasureing::",sessionState,isMeasuring);
    //   clearTimeout(loadingTimeoutPromise);
    //   setStartMeasuring(false);
    //   setFlag(true);
     
    // }
    
    });
  }, [sessionState, setIsLoading, processingTime]);
  
   



  // const checkAccess = useCallback(
  //   () => {
  //     setInterval(()=>{
  //       console.log('vitalSigns from binahmonitor',vitalSigns,sessionState,offlineMeasurements)
  
  
  //     },1000)

  //   },
  //   [toggleFlag],
  // );
  // useEffect(() => {
  
  //   console.log("window base url:::",window.location.href);
  //   var url="https://10.39.22.41:3000/scan?id=deefe032-fedb-41f7-b37d-ae405499390e"
  //    console.log("sharedurl:",url.split('/scan?id=')[1])
  //   if(window.location.href!="https://139.59.27.112:3000/"){
  //     if(window.location.href!=null || window.location.href!=undefined){
  //       console.log("shareduuid:");
  //       // if(window.location.href.split("/?")[1].split("ses_token=")[1]!=null){
  //       //   setToken(window.location.href.split("/?")[1].split("ses_token=")[1].split("%20uuid=")[0]);
  //       //   console.log("token:",ses_token)
  //       // }
       
  //         setuuid(window.location.href.split('/scan?id=')[1]);
  //         console.log("uuid:",uuid);
        
  //     }
  //   }else{
  //     console.log("shareduuid2:");
  //       setuuid(window.location.href.split('/scan?id=')[1]);
  //       console.log("uuid2:",uuid);
      
  //   }
    
  //  // console.log("window base url2:::",window.location.href.split("/?")[1].split("%20uuid=")[1]);
  // //  console.log("window base params:::",window.location);
  //   // {vitalSigns!==null&&vitalSigns!==undefined?()=>trackvitals({'log':vitalSigns,"ses_token":"14ca91a6-b53d-483c-a467-19c10fe0e8ea"
  //   // ,'uuid':"6de31b9b-f9cd-4332-88c0-ec68a37538a6","log_typ":'1',"platform":"","scan_id":20}):()=>{} }
  //   // console.log("rendering::")
  //   if (isMeasuring()) {
  //     setIsLoading(false);
  //     if (errorMessage) {
  //       setIsMeasurementEnabled(false);
  //     } else {
  //       setIsMeasurementEnabled(true);
  //     }
  //     !isPageVisible && setStartMeasuring(false);
  //   } else if (
  //     (sessionState === SessionState.ACTIVE ||
  //       sessionState === SessionState.TERMINATED) &&
  //     errorMessage
  //   ) {
  //     setIsMeasurementEnabled(false);
  //   }
  //   if (
  //     sessionState === SessionState.ACTIVE &&
  //     prevSessionState !== sessionState
  //   ) {
  //     setStartMeasuring(false);
  //     setIsLoading(false);
  //   }
  // }, [errorMessage, sessionState, isPageVisible]);

  
//  const countTimer=(timer:number)=>{
//   console.log("timer::",timer)
//  const intervalId= setInterval(() => {
//     setProgress((prevProgress) => {
//       const newProgress = prevProgress + 1;
//       if (newProgress >= 60) {
//         clearInterval(intervalId);
//       }
//       return newProgress;
//     });
//   }, (timer/60) * 1000); 
//  }



  //  useEffect(()=>{
   
  //  // console.log("scanning;:::",`${new Date().toLocaleString()}`);
  //   const dateTime = new Date();

  //   const formdata = new FormData();
    
  //   const scanid=dateTime.getDay().toString()+dateTime.getMonth().toString()+dateTime.getFullYear().toString()
  //   +dateTime.getHours().toString()+dateTime.getMinutes().toString()+dateTime.getSeconds().toString()+
  //   dateTime.getMilliseconds().toString();
  //   // console.log("vitalsign;:::",vitalSigns);
  //   const log_type=1;
  //   //progress==180
  //     if(progress==60){
  //       const score=calculateScoreFromLog(vitalSigns);
  //       //setscore(calculateScoreFromLog(vitalSigns));
  //       console.log("wellnescore::",score,ses_token,uuid);
  //         console.log("vitalsign;:::",vitalSigns);
  //         //formdata.append("ses_token",ses_token==""?"3b88168a-9005-46b9-91a1-48ba632f335c":ses_token);
  //        // formdata.append("uuid",uuid==""?"6de31b9b-f9cd-4332-88c0-ec68a37538a6":uuid),
  //        formdata.append("share_link_id",uuid),
  //         formdata.append("log_type",log_type.toString());
  //         formdata.append("platform","ios");
  //         formdata.append("scan_id",scanid);
  //        // formdata.append("Score",`${score}`);
  //         formdata.append("log",JSON.stringify({"HeartRate":vitalSigns.heartRate.value==undefined?"":vitalSigns.heartRate.value,
  //   "OxygenSaturation":vitalSigns.oxyzenssturation.value==undefined?"":vitalSigns.oxyzenssturation.value,
  //   "BreathingRate":vitalSigns.breathingRate.value==undefined?"":vitalSigns.breathingRate.value,
  //   "Sdnn":vitalSigns.hrvSdnn.value==undefined?"":vitalSigns.hrvSdnn.value,
  //   "StressLevel":vitalSigns.stress.value==undefined?"":vitalSigns.stress.value,
  //   "BloodPressure":vitalSigns.bloodPressure.value==undefined?"":vitalSigns.bloodPressure.value,
  //   "Hemoglobin":vitalSigns.hemoglobin.value==undefined?"":vitalSigns.hemoglobin.value,
  //   "HemoglobinA1C":vitalSigns.hemoglobinA1c.value==undefined?"":vitalSigns.hemoglobinA1c.value,
  //   "PRQ":vitalSigns.prq.value==undefined?"":vitalSigns.prq.value,"Score":score??""}))
  //       trackvitals(formdata)
  //    .then(data=>{
  //     console.log("dataerr:",data);
  //     if(data==undefined || data['status']===0){
  //       setmsg(data["message"])
  //      console.log("failed to scan vitals::",data)
  //      settrack(false);
  //     }else{
  //       setmsg(data["message"])
  //       console.log("scan vitals::",data)
  //       console.log("scan heartrate::",vitalSigns.heartRate.value)
  //       console.log("scan heartrate2::",vitalSigns.heartRate)
  //       setScanres(data["status"]);
  //       settrack(true);
  //      // countTimer(5)
  //     window.location.assign("https://app.geras.ai/vitalsinfo?vitals="+JSON.stringify(vitalSigns)+"&score="+score);
    
  //     }
  //    })
  //   }
  //    else if(flag==true){
  //       console.log("on stop timer::");
  //       const dateTime2 = new Date();

  //   const formdata2 = new FormData();
  //   const score2=calculateScoreFromLog(vitalSigns);
  //   //setscore(calculateScoreFromLog(vitalSigns));
  //   const scanid2=dateTime2.getDay().toString()+dateTime2.getMonth().toString()+dateTime2.getFullYear().toString()
  //   +dateTime.getHours().toString()+dateTime2.getMinutes().toString()+dateTime2.getSeconds().toString()+
  //   dateTime.getMilliseconds().toString();
  //   formdata2.append("share_link_id",uuid),
  //   formdata2.append("log_type",log_type.toString());
  //   formdata2.append("platform","ios");
  //   formdata2.append("scan_id",scanid2);
  //  // formdata2.append("Score",`${score2}`);
  //   formdata2.append("log",JSON.stringify({"HeartRate":vitalSigns.heartRate.value==undefined?"":vitalSigns.heartRate.value,
  // "OxygenSaturation":vitalSigns.oxyzenssturation.value==undefined?"":vitalSigns.oxyzenssturation.value,
  // "BreathingRate":vitalSigns.breathingRate.value==undefined?"":vitalSigns.breathingRate.value,
  // "Sdnn":vitalSigns.hrvSdnn.value==undefined?"":vitalSigns.hrvSdnn.value,
  // "StressLevel":vitalSigns.stress.value==undefined?"":vitalSigns.stress.value,
  // "BloodPressure":vitalSigns.bloodPressure.value==undefined?"":vitalSigns.bloodPressure.value,
  // "Hemoglobin":vitalSigns.hemoglobin.value==undefined?"":vitalSigns.hemoglobin.value,
  // "HemoglobinA1C":vitalSigns.hemoglobinA1c.value==undefined?"":vitalSigns.hemoglobinA1c.value,
  // "PRQ":vitalSigns.prq.value==undefined?"":vitalSigns.prq.value,"Score":score2??""}))
  //       trackvitals(formdata2)
  //       .then(data=>{
  //        console.log("dataerr:",data);
  //        if(data==undefined || data['status']===0){
  //         setmsg(data["message"])
  //         console.log("failed to scan vitals::",data)
  //         settrack(false);
  //        }else{
  //         setmsg(data["message"])
  //          console.log("scan vitals::",data)
  //          console.log("scan heartrate::",vitalSigns.heartRate.value)
  //          console.log("scan heartrate2::",vitalSigns.heartRate)
  //          setScanres(data["status"]);
  //          settrack(true);
  //         // countTimer(5)
  //         window.location.assign("https://app.geras.ai/vitalsinfo?vitals="+vitalSigns+"&score="+score2)
         
  //        }
  //       })
  //       console.log("is not measuring")
  //     }
      
  //  },[progress])



  useEffect(() => {

    //console.log("console::",vitalSigns);
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);

  const mobile = useMemo(() => isMobile(), []);
  const desktop = useMemo(() => !isTablet() && !isMobile(), []);

  return (
    <>
    
      <TopBar onSettingsClick={onSettingsClick} isMeasuring={isMeasuring()} />
      <MonitorWrapper isSettingsOpen={isSettingsOpen}>
        <MeasurementContentWrapper isMobile={mobile}>
          {/* <InfoBarWrapper>
            <InfoBar
              showTimer={isMeasurementEnabled && !isMediaTablet}
              isMeasuring={isMeasuring()}
              durationSeconds={processingTime}
              offlineMeasurements={offlineMeasurements}
            />
          </InfoBarWrapper> */}
          <VideoAndStatsWrapper isMobile={mobile}>
            <VideoWrapper>
              {isVideoReady() && <Img src={Mask} isDesktop={desktop} /> }
             { <Video
                ref={video}
                id="video"
                muted={true}
                playsInline={true}
                isMobile={isMobile()}
              >
              </Video>}
            </VideoWrapper>
            <div style={{height:"10px"}}></div>
           
              {barflag && !is_sending_message ?<ProgressBar totalTime={60}></ProgressBar>:<div></div>}
          
            {/* {(isMeasuring()
              ? !errorMessage && !warningMessage
              : !errorMessage) &&
              isMeasurementEnabled && <Stats vitalSigns={vitalSigns} />} */}


               {isVideoReady() && !is_sending_message &&
              <Stats vitalSigns={vitalSigns} 
              isMeasuring={isMeasuring()} isloading={isLoading}
               onClick={handleButtonClick} toggle={toggleFlag}
               isScanReadyToSend={isScanReadyToSend}
               handleSetScanReadyToSend={handleSetScanReadyToSend}
               setTheMsg={setTheMsg}
               setIsSendingMessage={setIsSendingMessage}
               ses_token={ses_token}
               uuid={uuid}
               />}

               {/*toggle button switch camer*/}
              {/* <InfoAlert message={info.message} /> */}
             
            <ErrorAlert message={errorMessage} />
            <h6 style={{paddingRight:"10px",paddingTop:"10px",fontSize:"15px"}}>{scanres}</h6>
            {isMeasuring() && <WarningAlert message={warningMessage} />}
            {track? <WarningAlert message={"Tracked successfully"} />:<WarningAlert message={showmsg} />}
            {/* {isMeasuring() && <InfoAlert message={info.message} />} */}

            {is_sending_message && <Loader />}
            {!isVideoReady() && licenseKey && <Loader />}
          </VideoAndStatsWrapper>
          
          {/* <ButtomTimerWrapper>
            {isMeasurementEnabled && (
              <Timer started={isMeasuring()} durationSeconds={processingTime} />
            )}
          </ButtomTimerWrapper> */}
          {/* <ButtonWrapper>
            <StartButton
              isLoading={isLoading}
              isMeasuring={isMeasuring()}
              onClick={handleButtonClick}
              vitalsing={vitalSigns}
            />
          </ButtonWrapper> */}
        </MeasurementContentWrapper>
      
      </MonitorWrapper>
     
    </>
  );
};

export default BinahMonitor;

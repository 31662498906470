import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-grid-system';
import { Flex } from './shared/Flex';
import styled from 'styled-components';

const ProgressBar = ({ totalTime }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= 60) {
          clearInterval(intervalId);
        }
        return newProgress;
      });
    }, (totalTime / 60) * 1000); // Adjust the division factor as needed

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, [totalTime]);

  return (

   
 
 <div  style={{position: "absolute",
flexDirection: "row",
 alignItems: "center",
justifyContent: "center",
height: "5px",
width:"50%",
// background-color: rgba(255, 255, 255, 0.8);
//progress*(.57)
backgroundColor: "#ffffff",
borderRadius: "25px",
bottom: "110px",
boxSizing: "border-box"}}>
      <div
        style={{
          width: `${progress*(1.71)}%`,
          height: '5px',
          paddingTop:"5px",
          borderRadius:"15px",
          backgroundColor: '#4285F4',
        }}
      />
      <p style={{color:'white'}}>{progress} sec</p>
    </div>

  
   
   
    
   

  );
};

export default ProgressBar;
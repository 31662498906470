import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BinahMonitor from './BinahMonitor';
import SettingsBars from './SettingsBars';
import { Flex } from './shared/Flex';
import { useCameras, useDisableZoom } from '../hooks';
import UAParser from 'ua-parser-js';
import Modal from 'react-modal';
import headerIcon from '../assets/headerIcon.svg'
import closeIcon from '../assets/closeIcon.svg';


const Container = styled(Flex)<{ isSettingsOpen: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${({ isSettingsOpen }) =>
    isSettingsOpen && 'rgba(0, 0, 0, 0.5)'};
`;


function validateUrl(scan_now,success){

const urlParams = new URLSearchParams(window.location.search);
const share_link_id = urlParams.get('id');
var formdata = new FormData();
formdata.append("share_link_id", share_link_id);
formdata.append("scan_now", scan_now);

var requestOptions:any = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://3g2fy811x9.execute-api.us-east-1.amazonaws.com/dev/share_link", requestOptions)
  .then(response => response.json())
  .then(result => {

    if(result.status==0){
      window.location.href = 'https://geras.ai/invalid_link.html';
    }else{

    success();
    }

  })
  .catch(error => console.log('error', error));
}
validateUrl(false,()=>{});

const App = () => {



  const cameras = useCameras();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [isMobile] = useState(
    UAParser(navigator.userAgent).device.type === 'mobile',
  );
  useDisableZoom();

  const onSettingsClickedHandler = useCallback((event) => {
    const settingsBars = document.getElementById('settingsBars');
    const isSettingsButtonClicked = event.target.id === 'settingsButton';

    const isInsideSettingsClicked =
      settingsBars.contains(event.target as Node) || isSettingsButtonClicked;

    if (!isInsideSettingsClicked) {
      setIsSettingsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', onSettingsClickedHandler);
    return () => {
      document.removeEventListener('click', onSettingsClickedHandler);
    };
  }, []);

  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  const toggleSettingsClick = useCallback(() => {
    setIsSettingsOpen(!isSettingsOpen);
  }, [isSettingsOpen]);

  const handleCloseSettings = useCallback(({ cameraId }) => {
    setCameraId(cameraId);
    setIsSettingsOpen(false);
  }, []);

  useEffect(() => {
      if (!cameras?.length) return;
      setCameraId(cameras[0].deviceId);
      console.log("deviceid::",cameras[0].deviceId)
    
  }, [cameras]);


const Button = styled.div
` display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 10px 20px;
  border-radius: 25px;
  background-color: #4285F4;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;`;

  const ModelStyle = {
    overlay: {
          'display': 'flex',
          'justify-content': 'center',
          'align-items':'center',
          'backgroundColor': 'rgba(0, 0, 0, 0.7)',
        },
    content: {
      'display': 'flex',
      'position':'relative',
      'inset':'auto',
      'margin': '10%',
      'width': '400px',
      'height': 'auto',
      'flex-direction': 'column',
      'justify-content': 'space-between',
      'border-radius': '10px',
    },
  };

  const ButtonWrapper = styled.div
  `
  @media (max-width:767px) {
    display: flex;
    justify-content: center;
  }
  display: flex;
  justify-content: center;
  `;

  const ModelWrapper = styled.div
  `
  display: flex;
  justify-content: center;
  `;

  const ContentOfPopUpWrapper = styled.div
  `overflowY: 'auto';
  `;

  const HeaderText = styled.div`
  @media (max-width:767px) {
    display: flex;
  justify-content: center;
  color: #4285F4;
  font-weight: bold;
  font-size: 17px;
  margin-down: 15px;
  }
  display: flex;
  justify-content: center;
  color: #4285F4;
  font-weight: bold;
  font-size: 20px;
  margin-down: 20px;
  `;

  const DisclamerText = styled.div`
  @media (max-width:767px) {
    color: black;
  font-size: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: justify;
  }
  color: black;
  font-size: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: justify;
  `;

  const SubTitleText = styled.div`
  @media (max-width:767px) {
    color: black;
  font-weight: bold;
  font-size: 10px;
  margin-down: 5px;
  }
  color: black;
  font-weight: bold;
  font-size: 14px;
  margin-down: 10px;
  `;

  const HeaderTextWithIconWrapper = styled.div`
  @media (max-width:767px) {
    display: flex;
  justify-content: center;
  }
  display: flex;
  justify-content: center;
  `;
  const PointTextWrapper = styled.ul`
  @media (max-width:767px) {
    list-style-position: outside;
    padding: 10px;

  }
  list-style-position: outside;
  padding: 10px;
  `;

  const PointText = styled.li`
  @media (max-width:767px) {
    color: black;
  font-size: 10px;
  margin-left: 5px;
  text-align: justify;
  list-style-position: outside;
  }
  &::marker {
    color: #4285F4;
  }
  color: black;
  font-size: 14px;
  margin-left: 5px;
  text-align: justify;
  list-style-position: outside;
  `;

  const RedDisclamerText = styled.span
  `
  @media (max-width:767px){
    color: red;
    margin-right: 5px;
    font-size: 9px;
  }
  color: red;
  margin-right: 5px;
  font-size: 11px;
  `;

  const HeaderLogoWrapper = styled.img
  `
  @media (max-width:767px){
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  width: 25px;
  height: 25px;
  margin-right: 5px;
  
  `;

  const ImportantText =  styled.div
  `
  @media (max-width:767px) {
    display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin: 10px 0;
  text-align: center;
  }
  display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
  `;

  const TopCloseIconWraper = styled.div
  `
  display: flex;
  justify-content: end;
  `;

  const CloseLogo = styled.img
  `
  @media (max-width:767px){
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
  width: 15px;
  height: 15px;
  margin-left: 5px;
  
  `;

  return (
   
    <Container isSettingsOpen={isSettingsOpen}>
    <Modal
      isOpen={isPopupOpen}
      onRequestClose={() => setIsPopupOpen(false)}
      style={ModelStyle}
      // className="centered-modal" 
      // overlayClassName="overlay"
      >
          <ContentOfPopUpWrapper>
            <TopCloseIconWraper>
              <CloseLogo src={closeIcon} onClick={() => setIsPopupOpen(false)}/>
            </TopCloseIconWraper>
            <HeaderTextWithIconWrapper>
            <HeaderLogoWrapper src={headerIcon} />
            <HeaderText>
            Action Required!
            </HeaderText>
            </HeaderTextWithIconWrapper>
            <ImportantText>
            Important points to be checked before proceeding
            </ImportantText>
            <SubTitleText>
            Front camera scan
            </SubTitleText>
        <PointTextWrapper>
          <PointText>
          Sit or stand still during the measurement in well-lit surroundings.{" "}
          </PointText>
          <PointText>
          Make sure your face is fully exposed; ensure that it is not covered with hair or accessories (i.e., mask, sunglasses/ spectacles, hat etc.){" "}
          </PointText>
          <PointText>
          Avoid moving or talking throughout the measurement and remain focused on the screen until the measurement is complete.{" "}
          </PointText>
          <PointText>
          Expose neither the camera nor the area in the frame to direct sunlight.{" "}
          </PointText>
          <PointText>
          Keep the phone on a stable surface or stand for a stable measurement reading{" "}
          </PointText>
        </PointTextWrapper>
          
            <DisclamerText>
            <RedDisclamerText> Disclaimer : </RedDisclamerText> The measured vital signs are not intended for medical use, including self-diagnosis or consultation with a doctor, and are only designed for general fitness and wellness purposes.{" "}
            </DisclamerText>
          </ContentOfPopUpWrapper>
          <ButtonWrapper>
          <Button  onClick={() => setIsPopupOpen(false)} >
          <div>
            OK
          </div>
        </Button>
        </ButtonWrapper>
      </Modal>

      <BinahMonitor
        showMonitor={!(isMobile && isSettingsOpen)}
        cameraId={cameraId}
        onLicenseStatus={updateLicenseStatus}
        onSettingsClick={toggleSettingsClick}
        isSettingsOpen={isSettingsOpen}
        validateUrl={validateUrl}
      />
      <SettingsBars
        open={isSettingsOpen}
        onClose={handleCloseSettings}
        cameras={cameras}
        isLicenseValid={isLicenseValid}
      />
    </Container>
  );
};

export default App;

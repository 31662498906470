import React,{useState} from 'react';
import styled from 'styled-components';
import { Flex } from '../shared/Flex';
import { Container, Row, Col } from 'react-grid-system';
import CameraView from "../Backcamera"
const Wrapper = styled(Flex)`
  position:absolute;
  top: 10px;
  //bottom:15px
 // height: 70px;
 height: 55px
  //width: 90%;
  width: 40%;
  border-radius:30px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
 padding: px 5px 5px 10px;
  box-sizing: border-box;
`;

const Message = styled.div`
  padding: 5px;
  font-size: 14px;
  color: #d84242;
  text-align: center;
`;

const InfoAlert = ({ message }) => {
  // if (!message) {
  //   return null;
  // }
  const webcamRef = React.useRef(null);
let[toggle,settogle]=useState(false)
let[ftoggle,setFtogle]=useState(true)

function startCamera () {
  navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
    .then((stream) => {
      webcamRef.current.srcObject = stream;
     // alert(" accessing camera")
    })
    .catch((error) => {
     // alert("Error accessing camera")
      console.log('Error accessing camera:', error);
    });
};

React.useEffect(() => {
  
}, []);

  function handleToggle(){
    console.log("toggle::",toggle);
      toggle=!toggle;
      settogle(toggle);
      
  }
 
 
  return (
    <Wrapper>
     <Container>
  <Row >
    
   <button onClick={handleToggle}
   style={{background:!toggle?"#4285F4":"white",width:"140px",border:"none",height:"45px",borderRadius:"25px",
  color:!toggle?"white":"#4285F4", minHeight: "auto",
  minWidth: "auto",position: "relative", display: "inline-block",}}>
   Face
   </button>
   
    <div style={{width:"10px"}}>
    </div>
   
    <button onClick={handleToggle}
    style={{background:toggle?"#4285F4":"#ffffff",width:"140px",border:"none",height:"45px",borderRadius:"25px",
  color:toggle?"#ffffff":"#4285F4",float:'right'}}>
   Finger
   </button>
    
  
  </Row>
</Container>
      {/* <Message>{message}</Message> */}
    </Wrapper>
  );
};
export default InfoAlert;
